/** ------Module configuration---------------- */
@import "./theme_settings";

/** ------Design system----------------------- */
@import "./theme_variables";
@import "./font_face";

/** Imports atoms to uses them in reset */
@import "./atoms/Typography/mixins";
@import "./atoms/Typography/Headline";
@import "./atoms/Typography/Body";
//TODO: These are actually define as molecules
@import "./atoms/Buttons/Generic";
@import "./atoms/Buttons/Misc";
@import "./atoms/Buttons/Navigation";

/** Imports molecules to uses them in reset */
@import "./molecules/Callouts/default";
@import "./molecules/Cards/card_process";
@import "./molecules/Cards/data-item";
@import "./molecules/Cards/upcoming_meetings";

/** ------Decidim customization--------------- */

/** Reset decidim stylesheets */
@import "./reset/author_avatar";
@import "./reset/author";
@import "./reset/badges";
@import "./reset/base";
@import "./reset/budget-list";
@import "./reset/budget-meter";
@import "./reset/buttons";
@import "./reset/callout";
@import "./reset/card";
@import "./reset/categories";
@import "./reset/collapsible";
@import "./reset/columns";
@import "./reset/comments";
@import "./reset/conversation";
@import "./reset/data-consent";
@import "./reset/data-picker";
@import "./reset/definition_data";
@import "./reset/docs-manager";
@import "./reset/dropdown_menu";
@import "./reset/errors";
@import "./reset/extra";
@import "./reset/filters";
@import "./reset/floating_helper";
@import "./reset/form";
@import "./reset/home_section";
@import "./reset/home";
@import "./reset/horizontal-tabs";
@import "./reset/icons";
@import "./reset/inline-filters";
@import "./reset/label";
@import "./reset/labels";
@import "./reset/layout";
@import "./reset/lines_breadcrumb";
@import "./reset/login";
@import "./reset/logo_wrapper";
@import "./reset/main_container";
@import "./reset/navbar";
@import "./reset/order-by";
@import "./reset/org-chart";
@import "./reset/pagination";
@import "./reset/process_header_phase";
@import "./reset/process_header";
@import "./reset/process_nav";
@import "./reset/process";
@import "./reset/progress_bar";
@import "./reset/quill";
@import "./reset/opinion_toggle";
@import "./reset/result_per_page";
@import "./reset/results";
@import "./reset/reveal";
@import "./reset/scope-picker";
@import "./reset/select";
@import "./reset/signup";
@import "./reset/statistics";
@import "./reset/switch";
@import "./reset/tabs";
@import "./reset/tags";
@import "./reset/thumbnail";
@import "./reset/timeline";
@import "./reset/title_bar";
@import "./reset/toggle";
@import "./reset/tooltip";
@import "./reset/typography";
@import "./reset/upload_modal";
@import "./reset/user-form";
@import "./reset/user";
@import "./reset/versions";
@import "./reset/wrapper";
@import "./reset/metric-chart";

/** Overrides organisms **/
@import "./organisms/account";
@import "./organisms/assembly";
@import "./organisms/accountability";
@import "./organisms/authorizations";
@import "./organisms/blog";
@import "./organisms/budgets";
@import "./organisms/debate";
@import "./organisms/footer";
@import "./organisms/groups";
@import "./organisms/logins";
@import "./organisms/meetings";
@import "./organisms/members";
@import "./organisms/messaging";
@import "./organisms/metrics";
@import "./organisms/notifications";
@import "./organisms/processes";
@import "./organisms/proposals";
@import "./organisms/search";
@import "./organisms/sortitions";
@import "./organisms/surveys";
@import "./organisms/text_page";
@import "./organisms/user_conversations";

@import "./molecules/Cards/documents";
@import "./molecules/Cards/extra";
