.register__separator {
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
  font-style: italic;
  margin: 2rem 0;

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--grey2);
    position: absolute;
    top: 50%;
    z-index: -1;
  }
}

.register__separator__text {
  display: inline-block;
  background: $light-gray;
  padding: 0 1rem;
  @extend .body-5;
  @extend .body-5-caps;
  color: var(--emphasis-lowest);
}

.m_devise-registrations--new .register-form {
  .card {
    padding: 1.5rem;
    box-shadow: none;
    margin-bottom: 1rem;
  }
}
.m_devise-sessions--new,
.m_devise-passwords,
.o-confirmations,
.o-unlocks {
  .register-form.new_user {
    @extend .card;
    padding: 1.5rem;
    box-shadow: none;
    margin-bottom: 1rem;
    padding-bottom: 1.5rem !important;
  }
  .card__content {
    margin-bottom: 1rem;
  }
  .register-form .card {
    box-shadow: none;
    border-width: 0;
  }

  .actions {
    margin-top: 1.5rem;
    text-align: center;
  }
}
.register-form {
  width: 100%;

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    height: 3rem;
  }

  h3 {
    display: table;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-align: left;
  }

  label > [type="checkbox"] {
    margin-bottom: 1rem;
  }

  .tos-text {
    padding: 0.75rem;
    border: 1px solid var(--grey2);
    margin: 0.75rem 0;
    max-height: 10rem;
    overflow: auto;
    font-style: italic;
  }

  #card__tos,
  #card__newsletter {
    text-align: center;
  }

  .actions {
    & > button {
      width: 100%;
    }
    & ~ p {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .field {
    width: 100%;
  }
}
.user-nickname
  label.is-invalid-label
  .row
  > span:last-of-type
  .form-error.is-visible {
  margin-left: 0 !important;
}
.user-nickname {
  width: 100%;
  label .row {
    > span:last-of-type {
      width: 100% !important;
    }
    > span:first-of-type {
      display: none;
      // this is the prefix div
      @extend .input-group-label;
      justify-content: center;
      padding: 0;
      border-#{$global-right}: 0;
      width: 8%;
      height: 3rem;
      border-top-left-radius: $input-radius;
      border-bottom-left-radius: $input-radius;

      .prefix {
        display: none;
        height: 2.9rem;
        padding-top: 0.8rem;
      }
    }

    > span:last-of-type {
      // this is the input div
      @extend .input-group-field;

      width: 92%;
      height: 3rem;

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  label.is-invalid-label .row {
    margin-bottom: $form-spacing;

    > span:first-of-type {
      // this is the prefix div
      background-color: var(--white);
      border-color: var(--red);
    }

    > span:last-of-type {
      // this is the input div
      height: 4.2rem;

      .form-error.is-visible {
        margin-left: -8%;
      }
    }
  }

  .help-text {
    margin-top: 1rem;
    @extend .body-5;
  }
}
