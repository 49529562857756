.btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 48px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover,
  &:focus {
    text-decoration: none !important;
    cursor: pointer;
  }
  &:has(svg),
  &.js--withicon {
    gap: rem-calc(8px);
  }
}

.btn--small {
  align-items: center;
  padding: 12px 16px;

  height: 34px;
}

.btn--small .btn--withicon,
.button.js--withicon {
  gap: 4px;
}

.btn--medium {
  padding: 16px 24px;

  height: 44px;
}

.btn--large {
  padding: 20px 80px;
  gap: 8px;

  height: 53px;
}

.btn--primary {
  background-color: var(--primary);
  color: var(--on-primary) !important;
  border: unset;

  &:hover,
  &:focus {
    background-color: var(--primary-dark);
    color: var(--on-primary) !important;
  }
}

.btn--danger {
  background-color: var(--red);
  color: white;
  &:hover {
    background-color: var(--red-dark);
  }
}

.btn--warning {
  background-color: var(--yellow);
  color: var(--emphasis-high);
  &:hover {
    background-color: var(--yellow-dark);
  }
}

.btn--disabled {
  background-color: transparent;
  color: var(--emphasis-lowest) !important;
  &:hover,
  &.hover,
  &.focus,
  &:focus {
    background-color: var(--black-6);
    color: var(--emphasis-lowest) !important;
    cursor: pointer;
  }
}

.btn--secondary {
  background-color: transparent;
  color: var(--primary) !important;
  border: var(--primary) solid 1px;

  &:hover,
  &:focus {
    background-color: var(--primary-10) !important;
    color: var(--primary) !important;
    cursor: pointer;
  }
}
