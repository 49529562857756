.o-authorizations {
  p.heading5,
  h1,
  .heading1 {
    @extend .heading-3;
  }
  .card {
    padding: 1.5rem 1rem;
    justify-content: center;
    & > .card__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      form {
        width: 100%;
      }
    }

    &.skip {
      @extend .body-4;
    }
  }
}
