$nav-border-width: 3px;

.process-nav {
  position: relative;
  background-color: var(--white);
  padding: 0.75rem 0.5rem;
  box-shadow: unset;
  @include breakpoint(small only) {
    padding: 0;
  }

  &::after {
    display: none;
  }

  ul {
    margin: 0;
    list-style: none;
  }

  .about-link {
    font-size: 80%;
  }

  @include breakpoint(medium) {
    padding: 0;

    ul {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-grow: 1;
    }

    ul::-webkit-scrollbar {
      display: none;
    }

    ul::after {
      display: inline-block;
      flex-grow: 1;
      content: "";
    }

    li {
      flex: 1;
      align-items: center;
      flex-grow: 1;
      display: flex;
      padding: 0;
    }

    .about-link {
      float: right;
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.process-nav__trigger {
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
}

.process-nav__trigger__icon {
  position: absolute;
  right: 0.5rem;
  top: 0.55rem;
}

.process-nav__content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @include breakpoint(mediumlarge only) {
    padding-right: 1rem !important;
  }
  @include breakpoint(large only) {
    padding-right: 1rem !important;
  }
  @include breakpoint(medium down) {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

.process-nav__content li {
  position: relative;
  a {
    display: block;
    flex: 1;
    padding: rem-calc(16) rem-calc(10) calc(rem-calc(16) + rem-calc(3px)) 1rem;
    @include breakpoint(large) {
      padding-left: rem-calc(10);
    }
    text-align: left;
    color: var(--emphasis-low);
    font-size: 16px;
    text-transform: none;
    @include breakpoint(large) {
      text-align: center;
    }
    &:hover,
    &:focus {
      color: var(--primary);
      text-decoration: none;
    }
    &::after {
      display: none;
    }
    @include breakpoint(small only) {
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: var(--grey2);
        z-index: 1;
      }
      &:hover,
      &:focus {
        &:after {
          background-color: var(--primary);
        }
      }
    }
  }

  @include breakpoint(small medium down) {
    margin-top: 0;
  }

  &.is-active {
    box-shadow: none;
    display: flex;

    & a,
    & a:hover,
    & a:focus {
      color: var(--primary);
      text-decoration: none;
      &:after {
        display: none;
      }
      @include breakpoint(small only) {
        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: var(--grey2);
          z-index: 1;
        }
      }
    }
  }
}

.process-nav__more {
  @extend .btn--nav-more;

  &:hover,
  &:focus {
    background: var(--primary);
    color: var(--on-primary);
    i {
      background-color: var(--on-primary);
    }
  }
  i {
    @include square(5px);

    border-radius: 100%;
    background-color: var(--on-primary);
    display: inline-block;
    margin-top: 5px;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 0.25rem;

    &:not(:last-child) {
      margin-right: 0.1rem;
    }
  }
}

.process-nav__hidden-content.dropdown-pane {
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  padding: 0;
  top: 0px !important;
  & > ul {
    padding-top: $global-padding * 0.5;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    border-top: var(--divider-border);
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    &:after {
      display: none;
    }

    & > li {
      width: 100%;
      padding: 0;
      margin: 0;
      a,
      a:hover {
        text-align: left;
        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: var(--grey2);
          z-index: 1;
        }
      }
    }
  }
}

.process-nav__hidden-content__more {
  @extend .process-nav__more;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  min-height: 48px;
  background-color: transparent;
  border-bottom: $border;
  padding-top: 1.25rem;
  padding-bottom: $card-padding-small;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  &,
  &:hover,
  &:focus {
    background-color: var(--white);
    color: var(--primary);
    i {
      background-color: var(--primary);
    }
  }
  i {
    background-color: var(--primary);
  }
}

.process-nav__link {
  @extend .body-4;
  @extend .body-4-link;
  text-transform: initial;
  color: var(--primary);
  display: inline-flex;
  align-items: center;
  padding: 0.62rem 1rem;
  &:hover,
  &.active,
  &:focus {
    background: var(--primary-10);
    text-decoration: none !important;
  }

  svg {
    margin-right: 0.4em;
    width: 25px;
    height: 25px;
    fill: var(--grey2);

    .stroked-shape {
      fill: none;
      fill-opacity: 1;
      stroke-width: 2.01957917;
      stroke-miterlimit: 4;
      stroke-dasharray: none;
      stroke-dashoffset: 0;
      stroke: $muted;
    }
  }

  &.process-nav__hidden-content__more {
    text-align: left;
  }
}

@include breakpoint(medium) {
  .process-nav__link.is-active {
    color: var(--primary);

    svg {
      fill: var(--primary);

      .stroked-shape {
        stroke: var(--primary);
      }
    }
  }
}
