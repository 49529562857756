#voted-budgets-list {
  border: 1px solid var(--green);
  .card--list__data-added {
    background: transparent;
  }
  .budget-list__icon {
    display: none;
  }
  .button {
    text-wrap: nowrap;
  }
}

.o_participatory_process {
  .card--list__check svg {
    width: 18px;
    height: 18px;
    fill: white;
    transform: translate(-49%, -2px);
  }
  .button {
    text-wrap: nowrap;
  }
}

.m_budgets-projects {
  .button.loading-spinner {
    width: 34px;
    height: 34px;
    padding: 0;
    background: transparent !important;
    border-color: transparent !important;
    &::before {
      width: 34px;
      height: 34px;
    }
    svg {
      display: none !important;
    }
  }
  &.m_budgets-projects--index {
    @include breakpoint(small only) {
      .budget-summary__progressbox > button.button:last-child {
        margin-top: 1rem;
      }
    }

    .budget-list__data .text-sm.text-success {
      text-wrap: nowrap;
    }
    .button {
      text-wrap: nowrap;
    }
    .button.budget-list__action {
      width: 34px;
      height: 34px;
    }
    .budget-list__data .button.budget,
    .budget-list__data .button.budget svg {
      width: 34px;
      height: 34px;
    }
    .budget-summary__selected-item {
      margin-bottom: 0.5rem;
    }

    .card__content.budget-summary__selected {
      & > button {
        @extend select;
        color: var(--emphasis-med);
        margin: 0;
        min-width: $dropdown-width;
        display: inline-flex;
        width: auto;
        align-items: center;
        justify-content: center;
        min-width: 0;

        strong {
          display: inline-block;
          margin-right: 0.25rem;
        }
        svg {
          display: none;
        }
      }
      .button_to button[type="submit"] {
        @extend .btn--secondary;
        border-radius: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  &.m_budgets-projects--show {
    .button.loading-spinner::before {
      width: 24px;
      height: 24px;
    }
    .button.budget-vote-button,
    .button.budget-list__action.success {
      font-size: 16px;
      display: flex;
      flex-direction: row-reverse;
      gap: 0.75rem;
      flex: 0;
      padding: 17px;
      background: var(--primary);
      text-wrap: nowrap;
      &:hover {
        background: var(--primary-dark);
      }
      .button_to {
        flex: 0;
        &:hover .button,
        .button {
          background-color: transparent !important;
          border: 1px solid var(--on-primary);
          height: 24px;
          width: 24px;
          padding: 6px;
          min-width: 0;
          &:hover,
          &:focus {
            background-color: var(--primary);
          }
        }
        .button.loading-spinner svg {
          fill: var(--primary);
        }
        svg {
          fill: var(--on-primary);
        }
      }
    }
  }
  .input-group-field {
    height: auto;
  }
  .dropdown.menu {
    transform: translateY(2px);
  }
  .budget-list__data__number {
    @extend .body-3;
    @extend .body-3-medium;
    color: var(--emphasis-med);
  }
  .collection-sort-controls .column {
    width: 100% !important;
  }
  .card.extra {
    .card__content {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .button_to {
      width: 100%;
    }
  }
  .card > .filters__section {
    display: none;
  }
  .muted-link.js-back-to-list {
    @extend .link--go-back;
  }
  .mini-title {
    color: var(--emphasis-med);
    .mini-title__strong {
      color: var(--emphasis-med);
    }
  }
  .view-header h2.heading2 {
    @extend .heading-3;
    margin-top: 5rem;
    margin-bottom: 0;
  }

  @include filters-area;
  .budget-list__data {
    background-color: transparent;
  }
  .budget-list__image {
    width: 7.5rem;
    height: 5.5rem;
  }
  .budget-list__title {
    font-weight: 400;
  }
  .budget-summary {
    .card__content {
      padding: 1.5rem;
    }
    .card__content,
    .card__content > p:last-child,
    .heading3 {
      margin-bottom: 1.5rem;
    }
    .budget-progress__meter {
      transform: translateY(-100%);
    }
    .button.small {
      @extend .btn--medium;
    }
    .progress.budget-progress {
      margin-bottom: 0;
    }
    .link {
      @extend .body-4;
      @extend .body-4-link;
      margin-top: 1rem;
    }
  }
}

.card.card--budget {
  .card-data__item strong.heading4 {
    @extend .body-4;
    @extend .body-4-medium;
  }
}
